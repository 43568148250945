import React, { useState, useContext, useEffect } from "react";
import classnames from "classnames";
import { ContentContext } from "../../../../context/ContentContext";
import rawStyles from "../TracksList.module.scss";
import { Version, RatingDropdown } from "../Components";

const formatVersionName = (name) => {
  if (!name) return '';
  
  // Replace any "(FRP Clean)" or "(FRP Dirty)" etc with just "(Clean)" or "(Dirty)"
  return name.replace(/\(FRP\s+([^)]+)\)/g, '($1)');
};

const renderVersions = ({
    size,
    track,
    styles = rawStyles,
    presetName,
    className,
    isBillboard
}) => {
    const [showRate, setShowRate] = useState(null);
    const [actualVersion, setActualVersion] = useState(null);
    const { VersionsData, fetchGenres } = useContext(ContentContext); 
    const trackVersionName =
        track.versions[0] && track.versions[0].nameToDisplay;
    useEffect(()=>{
        (!VersionsData) && (fetchGenres());
    },['',VersionsData]) 
    if (size === "superShort" || size === "longVersion") {
        return (
            <div className={classnames(styles.versions, styles[size])}>
                <p title={trackVersionName} data-id={track.versions[0].id}>
                    {formatVersionName(trackVersionName)}
                </p>
            </div>
        );
    } else {

        const versionsWithoutDuplicate = (VersionsData) ? getVersionsWithoutDuplicateAndRemixes(
            track.versions,
            track,
            isBillboard,
            presetName,
            VersionsData
        ) : (fetchGenres()) && getVersionsWithoutDuplicateAndRemixes(
            track.versions,
            track,
            isBillboard,
            presetName,
            VersionsData
        );
        let isAnyVersionDownloaded = false;
        versionsWithoutDuplicate.map((elem,i)=>{
            if (elem.downloaded) {
                isAnyVersionDownloaded = true;
            }
        })
       return (
            <div className={classnames(className, styles.versions)} style={{marginTop: '37px'}}>
                 {(versionsWithoutDuplicate) && (versionsWithoutDuplicate.length > 0) &&
                  versionsWithoutDuplicate.sort((a, b) => {
                    if (a.displayOrder === null && b.displayOrder === null) return 0; // both are null, so they're equal
                    if (a.displayOrder === null) return 1; // a is null, so it should appear after b
                    if (b.displayOrder === null) return -1; // b is null, so it should appear after a
                    return a.displayOrder - b.displayOrder; // otherwise, sort by displayOrder
                    }).map((elem, i) => {
                    return (
                        <div
                            key={elem.id}
                            className={styles.smallVersionContainer}
                        >
                            <Version
                                artist={track.artist}
                                elem={elem}
                                track={track}
                                setShowRate={setShowRate}
                                showRate={showRate}
                                setActualVersion={setActualVersion}
                                i={i}
                                isAnyVersionDownloaded={isAnyVersionDownloaded}
                            />
                            {showRate &&
                                track.rating === 0 &&
                                elem.id === showRate && (
                                    <RatingDropdown
                                        style={{}}
                                        offsetStyle="versionOffset"
                                        showRate={showRate}
                                        setShowRate={setShowRate}
                                        track={{
                                            ...track,
                                            versions: [actualVersion]
                                        }}
                                    />
                                )}
                        </div>
                    );
                })}
                {(track.tracks) && (track.tracks.lenght > 0) && track.tracks.map((elem, i) => {
                    return (
                        <div
                            key={elem.id}
                            className={styles.smallVersionContainer}
                        >
                            <Version
                                artist={track.artist}
                                elem={elem}
                                track={track}
                                setShowRate={setShowRate}
                                showRate={showRate}
                                setActualVersion={setActualVersion}
                                i={i}
                                isAnyVersionDownloaded={isAnyVersionDownloaded}
                                isAlbumTrack={true}
                            />
                            {showRate &&
                                track.rating === 0 &&
                                elem.id === showRate && (
                                    <RatingDropdown
                                        style={{}}
                                        offsetStyle="versionOffset"
                                        showRate={showRate}
                                        setShowRate={setShowRate}
                                        track={{
                                            ...track,
                                            versions: [actualVersion]
                                        }}
                                    />
                                )}
                        </div>
                    );
                })}
            </div>
        );
    }
};

function getVersionsWithoutDuplicateAndRemixes(
    list,
    track,
    isBillboard,
    presetName,
    VersionsData
) {
    // const lastVersion =
    //     list
    //         .filter(filterDependsOnPreset.bind(null, { presetName }))
    //         .sort(sortByReleasedDate)[0] || getDefaultPresetId(presetName);
    let result = {};

    const isItIntroOutro = !isBillboard && track.presetId === 14;
    const isItRemixes = !isBillboard && track.presetId === 9;

    if (isItIntroOutro) {
        result = findIntroOutroVersions(list, VersionsData, track);
    } else if (isItRemixes) {
        result = findRemixes(list, VersionsData, track);
    } else {
        result = findOriginalVersions(list, presetName, isBillboard, VersionsData, track);
    }

    if (result.Main) {
        result.Main.displayVersion = "Main";
    }

    return [...Object.values(result).sort(sortByReleasedDate)];
}

export function filterDependsOnPreset(
    { presetName, isBillboard = false },
    { presetId, name }
) {
    const isRemixPreset = presetName === "Remixes";
    const isIntroOutroPrest = presetName === "Intro / Outro";
    const isOriginTrack = presetId !== 14 && presetId !== 9;
    let result = isOriginTrack;

    if (isBillboard) {
        result = isOriginTrack;
    } else if (
        !presetName ||
        ["All", "Breakers", "DJ Tools", "Promo Albums"].includes(presetName)
    ) {
        result = true;
    } else if (isRemixPreset) {
        result = presetId === 9;
    } else if (isIntroOutroPrest) {
        result = presetId === 14;
    } else if (presetName === "Instrumentals") {
        result = isOriginTrack && name.includes("Instrumental");
    } else if (presetName === "Acapellas") {
        result = isOriginTrack && name.includes("Acapella");
    }

    return result;
}

export function getDefaultPresetId(presetName) {
    let result = 1;

    if (presetName === "Remixes") {
        result = 9;
    } else if (presetName === "Intro / Outro") {
        result = 14;
    }

    return { presetId: result };
}

function sortByReleasedDate(a, b) {
    return new Date(b.released) - new Date(a.released);
}

function findIntroOutroVersions(list, VersionsData, track = []) {
    const result = {};
    const { id } = track;
    for (const elem of list) {
        const { name, isBtnSet, subname, nameToDisplay, trackId } = elem;
        const nameToDisplayCleaned = nameToDisplay && nameToDisplay.replace(/\)\s*\(/g, ')(');
        let isClean =  nameToDisplayCleaned && nameToDisplayCleaned.toLowerCase().includes('clean');
        let isDirtyV =  name && (name.toLowerCase() === 'dirty' || name.toLowerCase() === 'io dirty');
        let isCleanV =  name && (name.toLowerCase() === 'clean' || name.toLowerCase() === 'io clean');
        let isDirty =  nameToDisplayCleaned && nameToDisplayCleaned.toLowerCase().includes('dirty');
        let isSnipHitz =  nameToDisplayCleaned && nameToDisplayCleaned.toLowerCase().includes('snip hitz');
        let isMegaKutz =  nameToDisplayCleaned && nameToDisplayCleaned.toLowerCase().includes('mega kutz');
        let isAcap =  nameToDisplayCleaned && nameToDisplayCleaned.toLowerCase().includes('acap');
        const elmVersions = nameToDisplay.match(/\((.*?)\)/g);
        //if(id === 401678){console.log(typeof elmVersions, elmVersions, 'typeof elmVersions, elmVersions----------');continue;}
        const isHasMain = (elmVersions && elmVersions.length > 0) ? elmVersions.map(el => el.toLowerCase()).includes('main') : false;

        let isDefaultMain = (((!elmVersions || elmVersions === null) && name.toLowerCase() === 'main') || isHasMain) ? true : false;
        let isAcapMega =  (isAcap && isMegaKutz) ? true : false;
        let displayOrder = null;
        if(id && trackId && id !== trackId){
            continue;
        }

        if (elem.presetId !== 14) {
            continue;
        }
        let suitableIntroOutroSubVersion = false;
        let specialCase = VersionsData.find((v) => {
            if (isBtnSet === true) {
                return v.downloadBtn.toLowerCase().trim() === name.toLowerCase().trim();
            } else {
                return v.name.toLowerCase().trim() === name.toLowerCase().trim();
            }
        });
        specialCase = typeof specialCase == 'undefined' ? true : false;
        let isSnipHitz2 = ((isCleanV || isDirtyV) && (isSnipHitz)) ? true : false;
        if(((name) && isDefaultMain === false && name.toLowerCase() === 'main') || specialCase || isSnipHitz2){
            let specialCaseBtn2 = elmVersions.find((elm) => {
                elm = elm.replace('(', '').replace(')', '');
                return VersionsData.find((v) => elm.toLowerCase() === v.name.toLowerCase());
            });
            let specialCaseBtn1 = elmVersions.find((elm) => {
                elm = elm.replace('(', '').replace(')', '');
                return (elm.toLowerCase().includes('snip')) && elem;
            });
            if((isSnipHitz2 || isSnipHitz) && specialCaseBtn1 && specialCaseBtn2){
                suitableIntroOutroSubVersion = specialCaseBtn1.replace(/\(|\)/g, "").trim()+' '+specialCaseBtn2.replace(/\(|\)/g, "").trim();
                suitableIntroOutroSubVersion = suitableIntroOutroSubVersion.replace(/hitz/ig, " ").replace(/\s+/g, " ").trim();
                displayOrder = 7;
            } else {
                suitableIntroOutroSubVersion = (specialCaseBtn1) && specialCaseBtn1.replace(/\(|\)/g, "").trim();
                let v = (!isBtnSet) && VersionsData.find((v) => {
                        return v.name.toLowerCase().trim() === name.toLowerCase().trim() && v.name.toLowerCase().trim() !== 'main';
                });
                (v && v.displayOrder) ? displayOrder = v.displayOrder : displayOrder = 7;
            }
           if (suitableIntroOutroSubVersion && typeof suitableIntroOutroSubVersion == 'string' && !result[suitableIntroOutroSubVersion]) {
                elem.displayOrder = displayOrder;
                elem.name = suitableIntroOutroSubVersion;
                elem.displayVersion = suitableIntroOutroSubVersion;
                elem.isBtnSet = true;
                result[suitableIntroOutroSubVersion] = { ...elem };
                continue;
            } else if (suitableIntroOutroSubVersion && typeof suitableIntroOutroSubVersion == 'string' && result[suitableIntroOutroSubVersion] && elem.downloaded) {
                result[suitableIntroOutroSubVersion].downloaded = true;
                continue;
            } else if (suitableIntroOutroSubVersion && typeof suitableIntroOutroSubVersion == 'string' && result[suitableIntroOutroSubVersion] && elem.isHightlighted) {
                result[suitableIntroOutroSubVersion].isHightlighted = true;
                continue;
            }
        }

        let downloadBtn = (isMegaKutz && !isAcapMega) && VersionsData.find((v) => {
            let ver = 'Mega Kutz';
            ver += isClean ? ' Clean' : '';
            ver += isDirty ? ' Dirty' : '';
            return v.name.trim() === ver.trim();
        });

        downloadBtn = (downloadBtn) ? downloadBtn : VersionsData.find((v) => {
            if (isBtnSet === true && subname == null && (isClean || isDirty) && !isAcap && !isMegaKutz && !isSnipHitz) {
                let ver = 'IO';
                ver += isClean ? ' Clean' : '';
                ver += isDirty ? ' Dirty' : '';
                return v.downloadBtn.trim() === ver.trim();
            } else if ((isBtnSet !== true || typeof isBtnSet === 'undefined') && subname == null && (isClean || isDirty) && !isAcap && !isMegaKutz && !isSnipHitz) {
                let ver = 'IO';
                ver += isClean ? ' Clean' : '';
                ver += isDirty ? ' Dirty' : '';
                return v.downloadBtn.trim() === ver.trim();
            } else if (isBtnSet === true && subname && name) {
                return v.displayName.trim() === `(${subname})(${name})`.trim();
            } else if (isBtnSet === true) {
                return v.downloadBtn.trim() === name.trim();
            } else if ((isBtnSet !== true || typeof isBtnSet === 'undefined') && subname) {
                return v.displayName.trim() === `(${subname})(${name})`.trim();
            } else {
                return v.name.trim() === name.trim();
            }
        });

        if(typeof downloadBtn == 'undefined') {
            continue;
        }
        downloadBtn && downloadBtn.displayOrder && (displayOrder = downloadBtn.displayOrder);
        downloadBtn = downloadBtn.downloadBtn;

        if (!result[downloadBtn]) {
            elem.displayOrder = displayOrder;
            elem.name = downloadBtn;
            elem.isBtnSet = true;
            result[downloadBtn] = { ...elem };
        } else if (result[downloadBtn] && elem.downloaded) {
            result[downloadBtn].downloaded = true;
        } else if (result[downloadBtn] && elem.isHightlighted) {
            result[downloadBtn].isHightlighted = true;
        }
    }

    return result;
}

function findRemixes(list, VersionsData, track = []) {
    const result = {};
    const { id } = track;
    for (const elem of list) {
        const { name, trackId, isBtnSet, subname } = elem;
        let displayOrder = null;
        if(id && trackId && id !== trackId){
            continue;
        }
        if (elem.presetId !== 9) {
            continue;
        }
        let downloadBtn = VersionsData.find((v)=> {
            if (isBtnSet === true) {
                return v.downloadBtn.trim() === name.trim();
            } else if ((isBtnSet !== true || typeof isBtnSet === 'undefined') && subname) {
                return (v.displayName.trim() === `(${subname})(${name})`.trim() || v.displayName.trim() === `(${name})`.trim());
            } else {
                return v.name.trim() === name.trim();
            }
        })
        if(typeof downloadBtn == 'undefined') {
            continue;
        }
        downloadBtn && downloadBtn.displayOrder && (displayOrder = downloadBtn.displayOrder);
        downloadBtn = downloadBtn.downloadBtn;
        if (!result[downloadBtn]) {
            elem.displayOrder = displayOrder;
            elem.name = downloadBtn;
            elem.isBtnSet = true;
            result[downloadBtn] = { ...elem };
        } else if (result[downloadBtn] && elem.downloaded) {
            result[downloadBtn].downloaded = true;
        } else if (result[downloadBtn] && elem.isHightlighted) {
            result[downloadBtn].isHightlighted = true;
        }
    }
    // console.log(result, 'result-----findRemixes-')
    return result;
}

function findOriginalVersions(list, presetName, isBillboard, VersionsData, track = []) {
    const result = {};
    const { id } = track;
    for (const elem of list) {
        const { name, trackId, isBtnSet, subname } = elem;
        let displayOrder = null;
        if(id && trackId && id !== trackId){
            continue;
        }

        if (elem.presetId === 14 || elem.presetId === 9) {
            continue;
        }
        let downloadBtn = VersionsData.find((v)=> {
            if (isBtnSet === true) {
                return v.downloadBtn.trim() === name.trim();
            } else if ((isBtnSet !== true || typeof isBtnSet === 'undefined') && subname) {
                return v.displayName.trim() === `(${subname})(${name})`.trim();
            } else {
                return v.name.trim() === name.trim();
            }
        })
        if(typeof downloadBtn == 'undefined') {
            (trackId && trackId === 326569) && console.log(VersionsData,name,elem, 'VersionsData,name,elem' );
            continue;
        }
        downloadBtn && downloadBtn.displayOrder && (displayOrder = downloadBtn.displayOrder);
        downloadBtn = downloadBtn.downloadBtn;

        if (
            !isBillboard &&
            presetName === "Instrumentals" &&
            name.includes("Instrumental") === false
        ) {
            continue;
        } else if (
            !isBillboard &&
            presetName === "Acapellas" &&
            name.includes("Acapella") === false
        ) {
            continue;
        }

        if (!result[downloadBtn]) {
            elem.displayOrder = displayOrder;
            elem.name = downloadBtn;
            elem.isBtnSet = true;
            result[downloadBtn] = { ...elem };
        } else if (result[downloadBtn] && elem.downloaded) {
            result[downloadBtn].downloaded = true;
        } else if (result[downloadBtn] && elem.isHightlighted) {
            result[downloadBtn].isHightlighted = true;
        }
    }

    return result;
}

export default renderVersions;
