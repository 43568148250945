import React, { useContext } from "react";
import Checkbox from "components/common/form/CheckBoxField";
import { ContentContext } from "context/ContentContext";
import styles from "./AllFilters.module.scss";
import { handleSetCheckbox } from "./SideFIlterHelper";

// Helper function to format version name
const formatVersionName = (name) => {
    if (!name) return '';
    
    // If it's just "FRP Clean" or "FRP Dirty", return just "Clean" or "Dirty"
    if (name.startsWith('FRP ')) {
        return name.slice(4);
    }
    
    // For names with parentheses, replace "(FRP Clean)" with "(Clean)"
    return name.replace(/\(FRP\s+([^)]+)\)/g, '($1)');
};

export default function GenreCheckbox({
    filters,
    setFilters,
    elem,
    commonKey,
    list = [] // Default to empty array if not provided
}) {
    // For display, use the formatted version name
    const displayName = formatVersionName(elem);
    
    // Check if this version has both regular and FRP variants in the complete list
    const hasMatchingVersion = (name) => {
        if (!list || !Array.isArray(list)) return false;
        const baseName = formatVersionName(name);
        return list.some(version => 
            formatVersionName(version) === baseName && version !== name
        );
    };

    // If this is an FRP version and there's a regular version in the list, don't render
    if (elem.startsWith('FRP ') && hasMatchingVersion(elem)) {
        return null;
    }

    // Custom onChange handler to handle both regular and FRP versions
    const handleChange = () => {
        const baseName = formatVersionName(elem);
        const allVersions = filters[commonKey];
        
        // Find all versions (regular and FRP) that match this base name
        const matchingVersions = allVersions.filter(version => 
            formatVersionName(version) === baseName
        );

        // If any matching version is already selected, remove all matching versions
        if (matchingVersions.length > 0) {
            const newVersions = allVersions.filter(version => 
                formatVersionName(version) !== baseName
            );
            setFilters({ ...filters, [commonKey]: newVersions });
        } else {
            // Add both regular and FRP versions if they exist in the complete list
            const versionsToAdd = [elem];
            
            // Only check for FRP variants if we have a valid list
            if (list && Array.isArray(list)) {
                if (elem.startsWith('FRP ')) {
                    const regularVersion = baseName;
                    if (list.includes(regularVersion)) {
                        versionsToAdd.push(regularVersion);
                    }
                } else {
                    const frpVersion = `FRP ${elem}`;
                    if (list.includes(frpVersion)) {
                        versionsToAdd.push(frpVersion);
                    }
                }
            }
            
            setFilters({ 
                ...filters, 
                [commonKey]: [...allVersions, ...versionsToAdd]
            });
        }
    };

    // Check if either regular or FRP version is selected
    const isChecked = filters[commonKey].some(version => 
        formatVersionName(version) === displayName
    );

    return (
        <Checkbox
            key={elem}
            classes={{
                root: styles.checkbox,
                input: styles.checkboxInput,
                checked: styles.checkboxInputChecked
            }}
            label={displayName}
            title={elem}
            reverse
            value={isChecked}
            onChange={handleChange}
        />
    );
}
