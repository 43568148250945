import React, { useContext, useMemo } from "react";
import routerDecorator from "components/helpers/withRouter";
import { ContentContext } from "../../../../context/ContentContext";
import { AuthContext } from "../../../../context/AuthContext";
import styles from "./Version.module.scss";

const Version = ({
  elem,
  i,
  artist,
  track,
  setShowRate,
  showRate,
  history,
  setActualVersion,
  isAnyVersionDownloaded,
  isAlbumTrack=false
}) => {
  const { downloadMedia } = useContext(ContentContext);
  const { user } = useContext(AuthContext);

  const doDownload = (downloadedElem) => {
    downloadMedia({
      mediaType: "audio",
      artist,
      mediaKey: downloadedElem.fileName,
      mediaName: track.name,
      id: track.id,
      version: downloadedElem.name,
      versionId: elem.id
    });
  };

  const onTryToDownload = () => {
    if (user) {
      if(typeof track.tracks !== 'undefined'){
        if (elem.rating > 0) {
          doDownload(elem);
        } else {
          setShowRate(elem.TvId);
          setActualVersion(elem);
        }
      }else{
        if (track.rating > 0) {
          doDownload(elem);
        } else {
          setShowRate((prevState) =>
            prevState === elem.id ? null : elem.id
          );
          setActualVersion(elem);
        }
      }
    } else {
      history.push("/register");
    }
  };

  const className = useMemo(() => {
    if (!isAnyVersionDownloaded && !elem.isHightlighted) {
      return styles.defaultVersion;
    }
    if (elem.downloaded) {
      return styles.activeVersion;
    }
    if (
      elem.isHightlighted &&
      (!elem.downloadDate || elem.downloadDate < elem.created)
    ) {
      return styles.newVersion;
    }
    
    return styles.defaultVersion;
  }, [
    elem.downloaded,
    elem.isHightlighted,
    elem.downloadDate,
    elem.created,
    isAnyVersionDownloaded
  ]);

  // Helper function to format version name
  const formatVersionName = (name) => {
    if (!name) return '';
    
    // If it's just "FRP Clean" or "FRP Dirty", return just "Clean" or "Dirty"
    if (name.startsWith('FRP ')) {
      return name.slice(4);
    }
    
    // For names with parentheses, replace "(FRP Clean)" with "(Clean)"
    return name.replace(/\(FRP\s+([^)]+)\)/g, '($1)');
  };

  // Check if this version has a matching version (FRP or regular)
  const hasMatchingVersion = () => {
    if (!elem.name || !track.versions) return false;
    
    const currentName = formatVersionName(elem.name);
    return track.versions.some(version => 
      version.id !== elem.id && 
      formatVersionName(version.name) === currentName
    );
  };

  // If this is an FRP version and there's a regular version, don't render
  if (elem.name && elem.name.startsWith('FRP ') && hasMatchingVersion()) {
    return null;
  }

  
   const buttonStyle = (elem.fileName && elem.fileName.includes("FRP") && !elem.downloaded)
    ? {
        backgroundColor: !elem.downloaded && elem.isHightlighted ? "var(--track-new-version)" : "var(--track-DIY-Versions-elem)",
        color: "#ffffff !important",
        padding: "8px 16px !important",
        borderRadius: "4px !important",
        cursor: "pointer !important",
        fontWeight: "500 !important",
        border: "1px solid var(--theme-track-version-color)",
      }
    : {};

  return ((isAlbumTrack === false) && (
    <button 
      key={elem.name + i} 
      onClick={onTryToDownload} 
      className={`${className} 
      ${elem.fileName && elem.fileName.includes("FRP") && !elem.downloaded ? styles.frpButton_green : ''}
      `}
      style={buttonStyle}
    > 
      <span>{formatVersionName(elem.displayVersion || elem.name)}</span>
    </button>
  ));
};

export default routerDecorator(Version);
